import {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Outlet, Route, Routes, Navigate} from "react-router-dom";

import {MenuType, SubMenuType} from './types/enums';
import TokenHelper from './utils/helpers/tokenHelper';
import Error403 from './pages/misc/Error403';
import Error404 from './pages/misc/Error404';
import PageLoader from "./components/loaders/PageLoader";

const CustomerFeedback = lazy(() => import('./pages/misc/CustomerFeedback'));

const Login = lazy(() => import('./pages/authentication/Login'));
const Register = lazy(() => import('./pages/authentication/Register'));
const VerifyDomain = lazy(() => import('./pages/authentication/VerifyDomain'));
const ResetPassword = lazy(() => import('./pages/authentication/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/authentication/ForgotPassword'));
const OnboardProfile = lazy(() => import('./pages/authentication/OnboardProfile'));

const Home = lazy(() => import('./pages/dashboard/home/Home'));
const Settings = lazy(() => import('./pages/dashboard/settings/Settings'));
const Banking = lazy(() => import('./pages/dashboard/entities/banking/Banking'));
const Restaurants = lazy(() => import('./pages/dashboard/entities/restaurants/Restaurants'));
const Procurements = lazy(() => import('./pages/dashboard/entities/procurements/Procurements'));

const Roles = lazy(() => import('./pages/dashboard/profiles/users/roles/Roles'));
const RoleInfo = lazy(() => import('./pages/dashboard/profiles/users/roles/RoleInfo'));
const UserProfile = lazy(() => import('./pages/dashboard/profiles/users/profile/UserProfile'));
const CreateUserRole = lazy(() => import('./pages/dashboard/profiles/users/roles/CreateUserRole'));
const UserProfileInfo = lazy(() => import('./pages/dashboard/profiles/users/profile/UserProfileInfo'));
const CreateUserProfile = lazy(() => import('./pages/dashboard/profiles/users/profile/CreateUserProfile'));

const BuyAddons = lazy(() => import('./pages/dashboard/subscriptions/BuyAddons'));
const Subscriptions = lazy(() => import('./pages/dashboard/subscriptions/Subscriptions'));
const BillingsHistory = lazy(() => import('./pages/dashboard/subscriptions/BillingsHistory'));

const CustomerSettings = lazy(() => import('./pages/dashboard/profiles/customers/CustomerSettings'));
const CustomerInfo = lazy(() => import('./pages/dashboard/profiles/customers/profile/CustomerInfo'));
const CustomerFeedbacks = lazy(() => import('./pages/dashboard/profiles/customers/CustomerFeedbacks'));
const CustomersProfile = lazy(() => import('./pages/dashboard/profiles/customers/profile/CustomersProfile'));
const EditCustomerProfile = lazy(() => import('./pages/dashboard/profiles/customers/profile/EditCustomerProfile'));
const CreateCustomerProfile = lazy(() => import('./pages/dashboard/profiles/customers/profile/CreateCustomerProfile'));
const ImportCustomerProfile = lazy(() => import('./pages/dashboard/profiles/customers/profile/ImportCustomerProfile'));

const Sales = lazy(() => import('./pages/dashboard/entities/sales/orders/Sales'));
const SalesInfo = lazy(() => import('./pages/dashboard/entities/sales/orders/SalesInfo'));
const RecordRepayment = lazy(() => import('./pages/dashboard/entities/sales/orders/RecordRepayment'));

const Inventories = lazy(() => import('./pages/dashboard/entities/inventories/Inventories'));
const Items = lazy(() => import('./pages/dashboard/entities/inventories/items/Items'));
const ItemInfo = lazy(() => import('./pages/dashboard/entities/inventories/items/ItemInfo'));
const CreateItem = lazy(() => import('./pages/dashboard/entities/inventories/items/CreateItem'));
const ImportItems = lazy(() => import('./pages/dashboard/entities/inventories/items/ImportItems'));
const ExportItems = lazy(() => import('./pages/dashboard/entities/inventories/items/ExportItems'));
const Reports = lazy(() => import('./pages/dashboard/entities/inventories/reports/Reports'));
const ReportInfo = lazy(() => import('./pages/dashboard/entities/inventories/reports/ReportInfo'));
const DownloadReport = lazy(() => import('./pages/dashboard/entities/inventories/reports/DownloadReport'));
const InventorySettings = lazy(() => import('./pages/dashboard/entities/inventories/InventorySettings'));
const CategoryInfo = lazy(() => import('./pages/dashboard/entities/inventories/categories/CategoryInfo'));
const Categories = lazy(() => import('./pages/dashboard/entities/inventories/categories/Categories'));
const CreateCategory = lazy(() => import('./pages/dashboard/entities/inventories/categories/CreateCategory'));
const ImportCategory = lazy(() => import('./pages/dashboard/entities/inventories/categories/ImportCategories'));
const ExportCategories = lazy(() => import('./pages/dashboard/entities/inventories/categories/ExportCategories'));
const StockIssuerInfo = lazy(() => import('./pages/dashboard/entities/inventories/stockIssuer/StockIssuerInfo'));
const StocksIssuer = lazy(() => import('./pages/dashboard/entities/inventories/stockIssuer/StocksIssuer'));
const ExportStockIssuer = lazy(() => import('./pages/dashboard/entities/inventories/stockIssuer/ExportStockIssuer'));
const CreateStockIssuer = lazy(() => import('./pages/dashboard/entities/inventories/stockIssuer/CreateStockIssuer'));
const StockTransfer = lazy(() => import('./pages/dashboard/entities/inventories/stockTransfer/StockTransfer'));
const StockTransferInfo = lazy(() => import('./pages/dashboard/entities/inventories/stockTransfer/StockTransferInfo'));
const ReceiveStockTransfer = lazy(() => import('./pages/dashboard/entities/inventories/stockTransfer/ReceiveStockTransfer'));

const ImportStockTransfers = lazy(() => import('./pages/dashboard/entities/inventories/stockTransfer/ImportStockTransfers'));
const ExportStockTransfers = lazy(() => import('./pages/dashboard/entities/inventories/stockTransfer/ExportStockTransfers'));
const CreateStockTransfer = lazy(() => import('./pages/dashboard/entities/inventories/stockTransfer/CreateStockTransfer'));
const Reconciliation = lazy(() => import('./pages/dashboard/entities/inventories/reconciliation/Reconciliation'));
const ReconciliationInfo = lazy(() => import('./pages/dashboard/entities/inventories/reconciliation/ReconciliationInfo'));
const ExportReconciliation = lazy(() => import('./pages/dashboard/entities/inventories/reconciliation/ExportReconciliation'));
const CreateReconciliation = lazy(() => import('./pages/dashboard/entities/inventories/reconciliation/CreateReconciliation'));
const StockAdjustments = lazy(() => import('./pages/dashboard/entities/inventories/stockAdjustments/StockAdjustments'));
const StockAdjustmentInfo = lazy(() => import('./pages/dashboard/entities/inventories/stockAdjustments/StockAdjustmentInfo'));
const CreateStockAdjustment = lazy(() => import('./pages/dashboard/entities/inventories/stockAdjustments/CreateStockAdjustment'));
const ExportStockAdjustments = lazy(() => import('./pages/dashboard/entities/inventories/stockAdjustments/ExportStockAdjustments'));


const App = () => {
  const AuthenticatedRoutes = () => {
    if (TokenHelper.isAuthenticationValid()) return <Outlet/>
    else return <Navigate to={`/${MenuType.AUTH}/${SubMenuType.VERIFY_DOMAIN}`} replace/>;
  };

  return (
    <Router>
      <Suspense fallback={<PageLoader/>}>
        <Routes>
          <Route path={MenuType.FEEDBACK} element={<CustomerFeedback/>}/>

          <Route path={MenuType.AUTH} element={<Outlet/>}>
            <Route index path={SubMenuType.LOGIN} element={<Login/>}/>
            <Route path={SubMenuType.REGISTER} element={<Register/>}/>
            <Route path={SubMenuType.VERIFY_DOMAIN} element={<VerifyDomain/>}/>
            <Route path={SubMenuType.RESET_PASSWORD} element={<ResetPassword/>}/>
            <Route path={SubMenuType.FORGOT_PASSWORD} element={<ForgotPassword/>}/>
            <Route path={SubMenuType.ONBOARD_PROFILE} element={<OnboardProfile/>}/>
          </Route>

          <Route element={<AuthenticatedRoutes/>}>
            {['/', MenuType.HOME, 'dashboard'].map(path => <Route path={path} key={path} element={<Home/>}/>)}
            <Route path={MenuType.USERS}>
              <Route path={SubMenuType.PROFILE} element={<Outlet/>}>
                <Route index element={<UserProfile/>}/>
                <Route path="create" element={<CreateUserProfile/>}/>
                <Route path=":name" element={<UserProfileInfo/>}/>
              </Route>
              <Route path={SubMenuType.ROLES} element={<Outlet/>}>
                <Route index element={<Roles/>}/>
                <Route path="create" element={<CreateUserRole/>}/>
                <Route path=":name" element={<RoleInfo/>}/>
              </Route>
            </Route>
            <Route path={MenuType.SALES} element={<Outlet/>}>
              <Route path={SubMenuType.TRANSACTION} element={<Outlet/>}>
                <Route index element={<Sales/>}/>
                <Route path=":ref" element={<SalesInfo/>}/>
                <Route path="record-repayment" element={<RecordRepayment/>}/>
              </Route>
            </Route>

            <Route path={MenuType.BANKING}>
              <Route path={SubMenuType.PROFILE} element={<Outlet/>}>
                <Route index element={<Banking/>}/>
              </Route>
            </Route>

            <Route path={MenuType.RESTAURANT_MANAGER}>
              <Route path={SubMenuType.PROFILE} element={<Outlet/>}>
                <Route index element={<Restaurants/>}/>
              </Route>
            </Route>

            <Route path={MenuType.INVENTORY} element={<Outlet/>}>
              <Route index path={SubMenuType.DASHBOARD} element={<Inventories/>}/>
              <Route path={SubMenuType.CATEGORIES} element={<Outlet/>}>
                <Route index element={<Categories/>}/>
                <Route path="create" element={<CreateCategory/>}/>
                <Route path="import" element={<ImportCategory/>}/>
                <Route path="export" element={<ExportCategories/>}/>
                <Route path=":name" element={<CategoryInfo/>}/>
              </Route>
              <Route path={SubMenuType.ITEMS} element={<Outlet/>}>
                <Route index element={<Items/>}/>
                <Route path="create" element={<CreateItem/>}/>
                <Route path="import" element={<ImportItems/>}/>
                <Route path="export" element={<ExportItems/>}/>
                <Route path=":name" element={<ItemInfo/>}/>
              </Route>
              <Route path={SubMenuType.STOCK_ISSUER} element={<Outlet/>}>
                <Route index element={<StocksIssuer/>}/>
                <Route path=":name" element={<StockIssuerInfo/>}/>
                <Route path="create" element={<CreateStockIssuer/>}/>
                <Route path="export" element={<ExportStockIssuer/>}/>
              </Route>
              <Route path={SubMenuType.STOCK_TRANSFER} element={<Outlet/>}>
                <Route index element={<StockTransfer/>}/>
                <Route path=":name" element={<StockTransferInfo/>}/>
                <Route path=":name/receive-stock-transfer" element={<ReceiveStockTransfer/>}/>
                <Route path="create" element={<CreateStockTransfer/>}/>
                <Route path="import" element={<ImportStockTransfers/>}/>
                <Route path="export" element={<ExportStockTransfers/>}/>
              </Route>
              <Route path={SubMenuType.STOCK_ADJUSTMENTS} element={<Outlet/>}>
                <Route index element={<StockAdjustments/>}/>
                <Route path=":name" element={<StockAdjustmentInfo/>}/>
                <Route path="create" element={<CreateStockAdjustment/>}/>
                <Route path="export" element={<ExportStockAdjustments/>}/>
              </Route>
              <Route path={SubMenuType.RECONCILIATION} element={<Outlet/>}>
                <Route index element={<Reconciliation/>}/>
                <Route path=":name" element={<ReconciliationInfo />} />
                <Route path="create" element={<CreateReconciliation/>}/>
                <Route path="export" element={<ExportReconciliation/>}/>
              </Route>
              <Route path={SubMenuType.REPORTS} element={<Outlet/>}>
                <Route index element={<Reports/>}/>
                <Route path=":subMenu" element={<ReportInfo/>}/>
                <Route path=":subMenu/download-report" element={<DownloadReport/>}/>
              </Route>
              <Route path={SubMenuType.SETTINGS} element={<InventorySettings/>}/>
            </Route>

            <Route path={MenuType.PROCUREMENT}>
              <Route path={SubMenuType.PROFILE} element={<Outlet/>}>
                <Route index element={<Procurements/>}/>
              </Route>
            </Route>

            <Route path={MenuType.CUSTOMER_MANAGEMENT} element={<Outlet/>}>
              <Route path={SubMenuType.PROFILE} element={<Outlet/>}>
                <Route index element={<CustomersProfile/>}/>
                <Route path=":email/edit" element={<EditCustomerProfile/>}/>
                <Route path="create" element={<CreateCustomerProfile/>}/>
                <Route path="import" element={<ImportCustomerProfile/>}/>
                <Route path=":name" element={<CustomerInfo/>}/>
              </Route>
              <Route path={SubMenuType.FEEDBACKS} element={<CustomerFeedbacks/>}/>
              <Route path={SubMenuType.SETTINGS} element={<CustomerSettings/>}/>
            </Route>

            <Route path={MenuType.SETTINGS} element={<Settings/>}/>

            <Route path={MenuType.SUBSCRIPTIONS} element={<Outlet/>}>
              <Route index element={<Subscriptions/>}/>
              <Route path={SubMenuType.ADD_ADDONS} element={<BuyAddons/>}/>
              <Route path={SubMenuType.BILLING_HISTORY} element={<BillingsHistory/>}/>
            </Route>
          </Route>

          <Route path="*" element={<Error404/>}/>
          <Route path="/403" element={<Error403/>}/>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;

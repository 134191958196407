import {checkNumberSign, formatNumber, toTitleCase} from "../../../utils/helpers/logicHelper";
import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {NumberSign} from "../../../types/enums";

const column: ColumnHelper<IStockLedger> = createColumnHelper<IStockLedger>()

export const StockLedgerExtensiveColumns = (currency: string): ColumnDef<IStockLedger, any>[] => [
  column.accessor('warehouse_name', {
    header: () => <span>Warehouse</span>,
    cell: info => <span className="text-nowrap">{info.getValue() ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('transaction_type', {
    header: () => <span>Transaction Type</span>,
    cell: info => <span className="text-nowrap">{toTitleCase(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('incoming_rate', {
    header: () => <span>Incoming Rate</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue(), true) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('valuation_rate', {
    header: () => <span>Valuation Rate</span>,
    cell: info => <span className="text-nowrap">
      {currency} {formatNumber(info.getValue(), true) ?? "0.00"}
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('balance_value', {
    header: () => <span>Balance Value</span>,
    cell: info => <span className="text-nowrap">
      {currency} {formatNumber(info.getValue(), true) ?? "0.00"}
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('change_value', {
    header: () => <span>Change Value</span>,
    cell: info => <span
      className={`text-nowrap ${checkNumberSign(info.row.original?.change_value) === NumberSign.NEGATIVE ? "text-danger-500" : "text-gray-700"}`}>
      {currency} {formatNumber(info.getValue(), true) ?? "-"}
    </span>,
    footer: info => info.column.id,
  }),
];

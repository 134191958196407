import {checkNumberSign, formatNumber} from "../../../utils/helpers/logicHelper";
import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {NumberSign} from "../../../types/enums";

const column: ColumnHelper<IBalanceValuation> = createColumnHelper<IBalanceValuation>()

export const InventoryValuationColumns = (currency: string): ColumnDef<IBalanceValuation, any>[] => [
  column.accessor('item_name', {
    header: () => <span>Item</span>,
    cell: info => <span className="text-nowrap">
           <span className="fw-bold">{info.getValue() ?? "-"}</span>
      <span className="d-block text-gray-500">
        {(info.row.original && info.row.original.item_code) ? info.row.original.item_code.toUpperCase() : ""}
      </span>
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('item_quantity', {
    header: () => <span>Available Qty</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('change_value', {
    header: () => <span>Total Value</span>,
    cell: info => <span
      className={`text-nowrap ${checkNumberSign(info.row.original?.change_value) === NumberSign.NEGATIVE ? "text-danger-500" : "text-gray-700"}`}>
      {currency} {formatNumber(info.getValue(), true) ?? "-"}
    </span>,
    footer: info => info.column.id,
  }),
];

import {MockCountry, MockState} from "./Configuration";

export const MockTenantProfile: ITenantDetails = {
  id: "",
  unique_url: "",
};

export const MockUserDetails: IUserDetails = {
  id: "",
  first_name: "",
  last_name: "",
  email: ""
}

export const MockUserProfile: IAuthenticationUser = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  user_type: "",
  tenant_details: {
    id: "",
    unique_url: ""
  },
  user_details: MockUserDetails,
  status: false,
  member_id: "",
  is_active: false,
  added_on: null,
  updated_on: null,
}

export const MockBusinessLocation: IBusinessLocation = {
  id: "",
  is_active: false,
  name: "",
  location_name: "",
  address: "",
  city: "",
  tenant: "",
  state: "",
  state_details: MockState,
  country_details: MockCountry,
  added_on: null,
  updated_on: null,
}

import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {format, isValid} from "date-fns";
import {formatNumber, parseDate, toTitleCase} from "../../../utils/helpers/logicHelper";

const column: ColumnHelper<IStockAdjustment> = createColumnHelper<IStockAdjustment>()

export const StockAdjustmentCompactColumns: ColumnDef<IStockAdjustment, any>[] = [
  column.accessor('adjustment_date', {
    header: () => <span>Date</span>,
    cell: info => <span className="text-nowrap">
            {(info.getValue() && isValid(parseDate(info.getValue())))
              ? <span>
                <span className="me-2">{format(parseDate(info.getValue()), "d LLLL, yyyy")}</span>
                <span className="ps-2 border-start border-gray-300"/>
                <br/>
                {format(parseDate(info.getValue()), "h:mm a")}
            </span>
              : "-"
            }
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('code', {
    header: () => <span>Adjustment ID</span>,
    cell: info => <span className="text-nowrap">{info.getValue() ?? '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('item_name', {
    header: () => <span>Item</span>,
    cell: info => <span className="text-nowrap">
            <span className="fw-bold">{info.getValue() ?? "-"}</span>
      <span className="d-block text-gray-500">
        {(info.row.original && info.row.original.item_code) ? info.row.original.item_code.toUpperCase() : ""}
      </span>
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('items', {
    header: () => <span>Qty</span>,
    cell: info => <span className="text-nowrap">{info.getValue() ? formatNumber(info.getValue()) : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('adjustment_type', {
    header: () => <span>Adjustment Type</span>,
    cell: info => <span className="text-nowrap">{info.getValue() ? toTitleCase(info.getValue()) : '-'}</span>,
    footer: info => info.column.id,
  }),
];

import { useEffect } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import "./assets/scss/style.scss";
import "./assets/css/animate.css";
import "cropperjs/dist/cropper.css";
import "photoswipe/dist/photoswipe.css";
import "react-datepicker/dist/react-datepicker.css";

import App from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import { onRTKPersist } from './store/slices/auth.slice';
import { ToastContextProvider, useToast } from './components/hooks/useToast';


const AppRoot = () => {
  const toast = useToast();

  useEffect(() => {onRTKPersist(store, toast).catch(err => console.error(err))}, []);

  return (
    <Provider store={store}>
      <ToastContextProvider position="top-right">
        <App />
      </ToastContextProvider>
    </Provider>
  );
}

const root: Root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot />);
reportWebVitals();

export const MockCountry: ICountry = {
    id: "",
    name: "",
    country: "",
    timezone: "",
    is_active: false,
    added_on: null,
    updated_on: null,
}

export const MockPermissionData: IPermissionData = {
    "Activate/Deactivate": false,
    "Add/Edit": false,
    "Delete": false,
    "Email": false,
    "Export": false,
    "Import": false,
    "Print": false,
    "View": false
};

export const MockRole: IRole = {
    id: "",
    value: "",
    name: "",
    is_active: false,
    added_on: null,
    updated_on: null,
    procurement: MockPermissionData,
    user_management: MockPermissionData,
    general_settings: MockPermissionData,
    inventory_manager: MockPermissionData,
    sales_and_payments: MockPermissionData,
    customer_management: MockPermissionData,
    banking_and_payments: MockPermissionData,
    items_and_price_management: MockPermissionData,
}

export const MockState: IState = {
    state: "",
    name: "",
    country: "",
}

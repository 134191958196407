import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {format, isValid} from "date-fns";
import {formatNumber, parseDate, toTitleCase} from "../../../utils/helpers/logicHelper";

const column: ColumnHelper<IStockTransfer> = createColumnHelper<IStockTransfer>()

export const StockTransferCompactColumns: ColumnDef<IStockTransfer, any>[] = [
  column.accessor('added_on', {
    header: () => <span>Date</span>,
    cell: info => <span className="text-nowrap">
            {(info.getValue() && isValid(parseDate(info.getValue())))
              ? <span>
                <span className="me-2">{format(parseDate(info.getValue()), "d LLLL, yyyy")}</span>
                <span className="ps-2 border-start border-gray-300"/>
                <br/>
                {format(parseDate(info.getValue()), "h:mm a")}
            </span>
              : "-"
            }
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('code', {
    header: () => <span>Transfer ID</span>,
    cell: info => <span className="text-nowrap">{info.getValue() ? info.getValue() : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('item_name', {
    header: () => <span>Item</span>,
    cell: info => <span className="text-nowrap">
            <span className="fw-bold">{info.getValue() ?? "-"}</span>
      <span className="d-block text-gray-500">
        {(info.row.original && info.row.original.item_code) ? info.row.original.item_code.toUpperCase() : ""}
      </span>
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('items', {
    header: () => <span>Qty</span>,
    cell: info => <span className="text-nowrap">
                {formatNumber(info.getValue() as string)}
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('created_by', {
    header: () => <span>Created By</span>,
    cell: info => <span className="text-nowrap">
        {info.getValue() ? toTitleCase(info.getValue()) : ''}&nbsp;
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('received_by', {
    header: () => <span>Received By</span>,
    cell: info => <span className="text-nowrap">
      {info.getValue() ? toTitleCase(info.getValue() as string) : '-'}
    </span>,
    footer: info => info.column.id,
  }),
];

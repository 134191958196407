import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {formatNumber} from "../../../utils/helpers/logicHelper";


const column: ColumnHelper<IProjection> = createColumnHelper<IProjection>()

export const ProjectionExtensiveColumns: ColumnDef<IProjection, any>[] = [
  column.accessor('item_name', {
    header: () => <span>Item</span>,
    cell: info => <span className="text-nowrap">
        <span className="fw-bold">{info.getValue() ?? "-"}</span>
      <span className="d-block text-gray-500">
        {(info.row.original && info.row.original.item_code) ? info.row.original.item_code.toUpperCase() : ""}
      </span>
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('warehouse', {
    header: () => <span>Warehouse</span>,
    cell: info => <span className="text-nowrap">{info.getValue() ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('category', {
    header: () => <span>Category</span>,
    cell: info => <span className="text-nowrap">{info.getValue() ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('quantity_projections.actual_quantity', {
    header: () => <span>Actual Quantity</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('quantity_in', {
    header: () => <span>Incoming Quantity</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('quantity_out', {
    header: () => <span>Outgoing Quantity</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('quantity_projections.projected_quantity', {
    header: () => <span>Projected Quantity</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
];

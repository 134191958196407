import {format, isValid} from "date-fns";
import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {formatNumber, parseDate, toTitleCase} from "../../../utils/helpers/logicHelper";


const column: ColumnHelper<ILowStockItem> = createColumnHelper<ILowStockItem>()

export const LowStockItemsCompactColumns = (currency: string): ColumnDef<ILowStockItem, any>[] => [
  column.accessor('item_name', {
    header: () => <span>Item</span>,
    cell: info => <span className="text-nowrap">
            <span className="fw-bold">{info.getValue() ?? "-"}</span>
      <span className="d-block text-gray-500">
        {(info.row.original && info.row.original.item_code) ? info.row.original.item_code.toUpperCase() : ""}
      </span>
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('warehouse', {
    header: () => <span>Warehouse</span>,
    cell: info => <span className="text-nowrap">{toTitleCase(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('low_stock_details.current_quantity', {
    header: () => <span>Current Quantity</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('added_on', {
    header: () => <span>Last Purchased</span>,
    cell: info => <span className="text-nowrap">
            {(info.getValue() && isValid(parseDate(info.getValue())))
              ? <span>
                <span className="me-2">{format(parseDate(info.getValue()), "d LLLL, yyyy")}</span>
            </span>
              : "-"
            }
        </span>,
    footer: info => info.column.id,
  }),
  column.accessor('valuation_rate', {
    header: () => <span>Item Valuation</span>,
    cell: info => <span className="text-nowrap">
      {currency} {formatNumber(info.getValue(), true) ?? "0.00"}
    </span>,
    footer: info => info.column.id,
  }),
];

import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {formatNumber} from "../../../utils/helpers/logicHelper";


const column: ColumnHelper<ILowStockItem> = createColumnHelper<ILowStockItem>()

export const LowStockItemsExtensiveColumns = (currency: string): ColumnDef<ILowStockItem, any>[] => [
  column.accessor('low_stock_details.cost_price', {
    header: () => <span>Cost Price</span>,
    cell: info => <span className="text-nowrap">
      {currency} {formatNumber(info.getValue(), true) ?? "-"}
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('low_stock_details.low_stock_reminder', {
    header: () => <span>Low Stock Reminder</span>,
    cell: info => <span className="text-nowrap">{formatNumber(info.getValue()) ?? "-"}</span>,
    footer: info => info.column.id,
  }),
];
